import React from 'react'
import { mount } from 'enzyme'
import PrimaryButton from '../common/PrimaryButton'

describe('PrimaryButton', () => {
  let props
  let mountedPrimaryButton
  const primaryButton = () => {
    if (!mountedPrimaryButton) {
      mountedPrimaryButton = mount(<PrimaryButton {...props} />)
    }

    return mountedPrimaryButton
  }

  beforeEach(() => {
    props = {
      isDisabled: false,
      children: 'Button Text'
    }

    mountedPrimaryButton = undefined
  })

  it('always renders a button', () => {
    const button = primaryButton().find('button')
    expect(button.length).toBe(1)
  })

  it('awlays renders its children', () => {
    const button = primaryButton()
      .find('button')
      .first()
    expect(button.props().children).toEqual(props.children)
  })

  describe('when `isDisabled` is passed', () => {
    beforeEach(() => {
      props.isDisabled = true
    })

    it('sets the button element as disabled', () => {
      const button = primaryButton()
        .find('button')
        .first()
      expect(button.props().disabled).toBeTruthy()
    })
  })
})
