import React from 'react'
import PropTypes from 'prop-types'

function SelectedSeatsInputs({ selectedSeats, nameSpace, rideId }) {
  return (
    <React.Fragment>
      {selectedSeats.map((seat, index) => (
        <React.Fragment key={seat.id}>
          {rideId && (
            <input
              type="hidden"
              name={`${nameSpace}[${index}][ride_id]`}
              value={rideId}
            />
          )}
          <input
            type="hidden"
            name={`${nameSpace}[${index}][level]`}
            value={seat.level}
          />
          <input
            type="hidden"
            name={`${nameSpace}[${index}][row]`}
            value={seat.row}
          />
          <input
            type="hidden"
            name={`${nameSpace}[${index}][seat]`}
            value={seat.seat}
          />
        </React.Fragment>
      ))}
    </React.Fragment>
  )
}

SelectedSeatsInputs.propTypes = {
  selectedSeats: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      level: PropTypes.string.isRequired,
      row: PropTypes.number.isRequired,
      seat: PropTypes.string.isRequired
    })
  ),
  nameSpace: PropTypes.string.isRequired,
  rideId: PropTypes.number
}

export default SelectedSeatsInputs
