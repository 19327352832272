export default function getNeighborSeat(seat, allSeats) {
  const neighborSeatLetter = getNeighbortSeatLetter(seat.seat)
  if (!neighborSeatLetter) return undefined

  const neighborSeatId = seat.row + neighborSeatLetter
  return allSeats[neighborSeatId] || undefined
}

function getNeighbortSeatLetter(seatLetter) {
  switch (seatLetter) {
    case 'A':
      return 'B'
    case 'B':
      return 'A'
    case 'D':
      return 'E'
    case 'E':
      return 'D'
    default:
      return 'C'
  }
}
