import React from 'react'

function WCSvg(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 28" {...props}>
      <path d="M5 6c1.7 0 3-1.3 3-3S6.7 0 5 0 2 1.3 2 3s1.3 3 3 3zm12.5 0c1.7 0 3-1.3 3-3s-1.3-3-3-3-3 1.3-3 3 1.3 3 3 3zM7.1 7H2.9C1.3 7 0 8.3 0 9.8v7.7h2.1V28h5.7V17.5H10V9.8C10 8.3 8.7 7 7.1 7zm13.2 1.9C19.9 7.8 18.8 7 17.6 7h-.2c-1.2 0-2.3.8-2.7 1.9L11 19.6h4.3V28h4.3v-8.4H24L20.3 8.9z" />
    </svg>
  )
}

export default WCSvg
