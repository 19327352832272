import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

class SearchInput extends PureComponent {
  render() {
    const {
      formIdentifier,
      value,
      onClick,
      label,
      subline,
      displayValue
    } = this.props
    var inputClasses = classNames('aSearchFormInput', {
      asInactive: formIdentifier === 'return_date' && value === ''
    })
    return (
      <div
        className={inputClasses}
        role="button"
        tabIndex="0"
        id={formIdentifier}
        onClick={onClick}
      >
        <label className="aSearchFormInput-label">{label}</label>
        <span className="aSearchFormInput-input">{displayValue}</span>
        <input
          name={formIdentifier}
          value={value}
          type="hidden"
          readOnly
          tabIndex="-1"
          className="aUnstyledInput aSearchFormInput-hidden"
        />
        <span className="aSearchFormInput-label">{subline}</span>
      </div>
    )
  }
}

SearchInput.propTypes = {
  formIdentifier: PropTypes.string.isRequired,
  value: PropTypes.string,
  displayValue: PropTypes.string.isRequired,
  subline: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired
}

SearchInput.defaultProps = {
  value: ''
}

export default SearchInput
