import React from 'react'

function CrossSvg(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...props}>
      <line
        strokeWidth="3"
        fill="none"
        stroke="currentColor"
        x1="1"
        y1="1"
        x2="17"
        y2="17"
      />
      <line
        strokeWidth="3"
        fill="none"
        stroke="currentColor"
        x1="17"
        y1="1"
        x2="1"
        y2="17"
      />
    </svg>
  )
}

export default CrossSvg
