import React from 'react'
import ViewFinderStyles from './__styles__/ViewFinder.module.sass'

class CustomViewFinder extends React.PureComponent {
  render() {
    return <div className={ViewFinderStyles.finder} />
  }
}

export default CustomViewFinder
