import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['tab', 'panel']

  initialize() {
    // this.showTab()
    this.index = 0
  }

  change(event) {
    this.index = this.tabTargets.indexOf(event.currentTarget)
  }

  showTab() {
    this.tabTargets.forEach((tab, localIndex) => {
      const panel = this.panelTargets[localIndex]
      if (localIndex === this.index) {
        tab.classList.add('asActive')
        panel.classList.add('asActive')
      } else {
        tab.classList.remove('asActive')
        panel.classList.remove('asActive')
      }
    })
  }

  get index() {
    return parseInt(this.data.get('index') || 0)
  }

  set index(value) {
    this.data.set('index', value)
    this.showTab()
  }
}
