import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import I18n from 'i18n-js'
import moment from 'moment'
import momentPropTypes from 'react-moment-proptypes'
import 'react-dates/initialize'
import OutsideClickHandler from 'react-outside-click-handler'
import { DayPickerSingleDateController as DatePicker } from 'react-dates'
import SearchDropdown from './SearchDropdown'
import SearchInput from './SearchInput'
import ClearableSearchInput from './ClearableSearchInput'
import 'react-dates/lib/css/_datepicker.css'

moment.locale(I18n.locale)

class SearchDatesContainer extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isEndDropdownVisible: false,
      isStartDropdownVisible: false
    }
  }

  readableDate = date => moment(date).format('DD.MM.YYYY')

  weekday = date =>
    moment(date)
      .locale(I18n.locale)
      .format('dddd')

  handleEndDateChange = date => {
    this.props.onEndDateChange(date)
  }

  handleStartDateChange = date => {
    this.props.onStartDateChange(date)
  }

  handleStartInputClick = () => {
    this.setState({
      isStartDropdownVisible: !this.state.isStartDropdownVisible,
      isEndDropdownVisible: false
    })
  }

  handleEndInputClick = () => {
    if (!this.props.endDate) {
      this.props.onEndDateChange(moment(this.props.startDate).add(1, 'days'))
    }

    this.setState({
      isStartDropdownVisible: false,
      isEndDropdownVisible: !this.state.isEndDropdownVisible
    })
  }

  handleOutsideClick = () => {
    this.setState({
      isStartDropdownVisible: false,
      isEndDropdownVisible: false
    })
  }

  handleStartDateFocusChange = () => {
    this.setState({
      isStartDropdownVisible: !this.state.isStartDropdownVisible
    })
  }

  handleEndDateFocusChange = () => {
    this.setState({ isEndDropdownVisible: !this.state.isEndDropdownVisible })
  }

  handleRemoveDateClick = () => {
    this.props.onEndDateChange(undefined)
    this.setState({ isEndDropdownVisible: false })
  }

  render() {
    const { isStartDropdownVisible, isEndDropdownVisible } = this.state
    const { startDate, endDate, isDayBlocked } = this.props
    return (
      <OutsideClickHandler onOutsideClick={this.handleOutsideClick}>
        <div className="aSearchInputGroup">
          <div className="aSearchInputGroup-item">
            <SearchDropdown
              inputElement={
                <SearchInput
                  label={I18n.t('js.search.travel_date.label')}
                  formIdentifier="depart_date"
                  value={moment(startDate).format('YYYY-MM-DD')}
                  displayValue={this.readableDate(startDate)}
                  subline={this.weekday(startDate)}
                  onClick={this.handleStartInputClick}
                />
              }
              dropdownElement={
                <React.Fragment>
                  <DatePicker
                    date={moment(startDate)}
                    onDateChange={this.handleStartDateChange}
                    focused={isStartDropdownVisible}
                    firstDayOfWeek={1}
                    isDayBlocked={isDayBlocked}
                    onFocusChange={this.handleStartDateFocusChange}
                    isOutsideRange={day =>
                      moment(day).isBefore(this.props.earliestDate, 'day') ||
                      moment(day).isSameOrAfter(this.props.latestDate, 'day')
                    }
                  />{' '}
                </React.Fragment>
              }
              hasVisibleDropdown={isStartDropdownVisible}
            />
          </div>
          <div className="aSearchInputGroup-item">
            <SearchDropdown
              inputElement={
                <ClearableSearchInput
                  label={I18n.t('js.search.return_date.label')}
                  formIdentifier="return_date"
                  value={endDate ? moment(endDate).format('YYYY-MM-DD') : ''}
                  displayValue={
                    endDate
                      ? this.readableDate(endDate)
                      : I18n.t('js.search.return_date.placeholder')
                  }
                  subline={
                    endDate
                      ? this.weekday(endDate)
                      : I18n.t('js.search.return_date.subline')
                  }
                  onClick={this.handleEndInputClick}
                  onClearance={endDate ? this.handleRemoveDateClick : undefined}
                />
              }
              dropdownElement={
                <React.Fragment>
                  <DatePicker
                    date={moment(endDate)}
                    onDateChange={this.handleEndDateChange}
                    firstDayOfWeek={1}
                    focused={isEndDropdownVisible}
                    isDayBlocked={isDayBlocked}
                    onFocusChange={this.handleEndDateFocusChange}
                    isOutsideRange={day =>
                      moment(day).isSameOrAfter(this.props.latestDate, 'day') ||
                      moment(day).isSameOrBefore(startDate, 'day')
                    }
                  />
                </React.Fragment>
              }
              hasVisibleDropdown={isEndDropdownVisible}
            />
          </div>
        </div>
      </OutsideClickHandler>
    )
  }
}

SearchDatesContainer.propTypes = {
  earliestDate: momentPropTypes.momentObj.isRequired,
  latestDate: momentPropTypes.momentObj.isRequired,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  onStartDateChange: PropTypes.func.isRequired,
  onEndDateChange: PropTypes.func.isRequired,
  isDayBlocked: PropTypes.func.isRequired
}

SearchDatesContainer.defaultProps = {
  isDayBlocked() {
    return false
  }
}

export default SearchDatesContainer
