import React from 'react'

class WheelchairSvg extends React.PureComponent {
  render() {
    return (
      <svg
        viewBox="0 0 16 21"
        xmlns="http://www.w3.org/2000/svg"
        {...this.props}
      >
        <path
          d="M3.6 8.6v2.1c-1.1.9-1.8 2.3-1.8 3.7 0 2.7 2.2 4.8 4.9 4.8 1.9 0 3.7-1.1 4.5-2.9l1.1 1.7c-1.2 1.9-3.3 3-5.6 3C3 21 0 18.1 0 14.4c0-2.5 1.4-4.7 3.6-5.8zm8.8.2c.5 0 .9.4.9.9s-.4.9-.9.9H8.8c0 2.3 3.2.8 4.7 3 .6 1 1.7 2.6 2.3 3.6.1.1.1.3.1.4 0 .3-.2.6-.4.8-.2.1-.8.2-1.2-.3-.6-.9-1.3-1.9-1.8-2.6-.7-1-1.2-1.3-3.7-1.3-1.8 0-3.6-.4-3.6-2.6V7.9c0-.5.2-.9.5-1.2.3-.3.8-.5 1.3-.5s.9.2 1.3.5.6.7.6 1.2v.9h3.5zM7.1 0c1.5 0 2.7 1.2 2.7 2.6S8.6 5.2 7.1 5.2 4.4 4.1 4.4 2.6 5.6 0 7.1 0z"
          fill="currentColor"
          fillRule="evenodd"
          clipRule="evenodd"
        />
      </svg>
    )
  }
}

export default WheelchairSvg
