import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

class SearchDropdown extends PureComponent {
  render() {
    const { inputElement, dropdownElement, hasVisibleDropdown } = this.props

    return (
      <div className="aSearchDropdown">
        <div>{inputElement}</div>
        {hasVisibleDropdown && (
          <div className="aSearchDropdown-dropdown">{dropdownElement}</div>
        )}
      </div>
    )
  }
}

SearchDropdown.propTypes = {
  inputElement: PropTypes.node.isRequired,
  dropdownElement: PropTypes.node.isRequired,
  hasVisibleDropdown: PropTypes.bool
}

export default SearchDropdown
