import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import I18n from 'i18n-js'

import styles from './__styles__/BusLevelTabs.module.sass'

function BusLevelTabs({ levels, activeLevel, onChange, isClickable }) {
  function handleTabClick(level, event) {
    if (!isClickable) return
    event.target.blur() // remove focus ring after click
    onChange(level)
  }

  return (
    <div className={styles.wrapper}>
      <Tab
        level={levels[0]}
        isActive={activeLevel === levels[0]}
        onClick={handleTabClick}
        isClickable={isClickable}
      />
      <div className={styles.separator} />
      <Tab
        level={levels[1]}
        isActive={activeLevel === levels[1]}
        onClick={handleTabClick}
        isClickable={isClickable}
      />
    </div>
  )
}

BusLevelTabs.propTypes = {
  levels: PropTypes.arrayOf(PropTypes.string).isRequired,
  activeLevel: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isClickable: PropTypes.bool
}

function Tab({ level, isActive, isClickable, onClick }) {
  function handleClick(event) {
    onClick(level, event)
  }

  const classes = classnames(styles.tab, {
    [styles.tabInactive]: !isActive
  })

  const TabElement = isClickable ? 'button' : 'div'
  const specialProps = isClickable
    ? { type: 'button', onClick: handleClick }
    : {}

  return (
    <TabElement {...specialProps} className={classes}>
      {I18n.t(`js.seatplan.${level}`)}
    </TabElement>
  )
}

Tab.propTypes = {
  level: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
  isClickable: PropTypes.bool
}

export default BusLevelTabs
