import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import I18n from 'i18n-js'
import OutsideClickHandler from 'react-outside-click-handler'
import SearchDropdown from './SearchDropdown'
import SearchInput from './SearchInput'
import LocationsList from './LocationsList'
import LocationsListItem from './LocationsListItem'

class SearchDestinationsContainer extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isEndDropdownVisible: false,
      isStartDropdownVisible: false
    }
  }

  handleStartLocationClick = location => {
    this.setState({
      isStartDropdownVisible: false
    })
    this.props.onStartCityChange(location)
  }

  handleEndLocationClick = location => {
    this.setState({
      isEndDropdownVisible: false
    })
    this.props.onEndCityChange(location)
  }

  handleStartInputClick = () => {
    this.setState({
      isStartDropdownVisible: !this.state.isStartDropdownVisible,
      isEndDropdownVisible: false
    })
  }

  handleEndInputClick = () => {
    this.setState({
      isStartDropdownVisible: false,
      isEndDropdownVisible: !this.state.isEndDropdownVisible
    })
  }

  handleOutsideClick = () => {
    this.setState({
      isStartDropdownVisible: false,
      isEndDropdownVisible: false
    })
  }

  renderLocationsList = (
    disabledLocation,
    keyPrefix,
    enabled,
    clickHandler
  ) => {
    const locations = [...this.props.cities]

    return (
      <LocationsList
        items={locations.map(location => (
          <LocationsListItem
            key={`${keyPrefix}-${location.name}`}
            value={location.name}
            isDisabled={
              !enabled &&
              !this.lineAvailable(
                disabledLocation && disabledLocation.slug,
                location.slug
              )
            }
            onClick={() => clickHandler(location)}
          />
        ))}
      />
    )
  }

  lineAvailable = (startLocation, endLocation) => {
    if (!startLocation || !endLocation) {
      return true
    }
    if (startLocation != this.props.requiredCity) {
      return endLocation == this.props.requiredCity
    }
    return startLocation != endLocation
  }

  render() {
    const { isStartDropdownVisible, isEndDropdownVisible } = this.state
    const { startCity, endCity } = this.props

    return (
      <OutsideClickHandler onOutsideClick={this.handleOutsideClick}>
        <div className="aSearchInputGroup">
          <div className="aSearchInputGroup-item">
            <SearchDropdown
              inputElement={
                <SearchInput
                  label={I18n.t('js.search.start_destination.label')}
                  formIdentifier="start_destination"
                  value={startCity && startCity.slug}
                  displayValue={
                    startCity
                      ? startCity.name
                      : I18n.t('js.search.start_destination.placeholder')
                  }
                  subline={
                    startCity
                      ? startCity.description
                      : I18n.t('js.search.start_destination.subline')
                  }
                  onClick={this.handleStartInputClick}
                />
              }
              dropdownElement={this.renderLocationsList(
                endCity,
                'start',
                true,
                this.handleStartLocationClick
              )}
              hasVisibleDropdown={isStartDropdownVisible}
            />
          </div>
          <div className="aSearchInputGroup-item">
            <SearchDropdown
              inputElement={
                <SearchInput
                  label={I18n.t('js.search.end_destination.label')}
                  formIdentifier="end_destination"
                  value={endCity && endCity.slug}
                  displayValue={
                    endCity
                      ? endCity.name
                      : I18n.t('js.search.end_destination.placeholder')
                  }
                  subline={
                    endCity
                      ? endCity.description
                      : I18n.t('js.search.end_destination.subline')
                  }
                  onClick={this.handleEndInputClick}
                />
              }
              dropdownElement={this.renderLocationsList(
                startCity,
                'end',
                false,
                this.handleEndLocationClick
              )}
              hasVisibleDropdown={isEndDropdownVisible}
            />
          </div>
        </div>
      </OutsideClickHandler>
    )
  }
}

SearchDestinationsContainer.propTypes = {
  cities: PropTypes.arrayOf(PropTypes.object).isRequired,
  lines: PropTypes.arrayOf(PropTypes.object),
  startCity: PropTypes.object,
  endCity: PropTypes.object,
  requiredCity: PropTypes.string.isRequired,
  onStartCityChange: PropTypes.func.isRequired,
  onEndCityChange: PropTypes.func.isRequired
}

export default SearchDestinationsContainer
