import React from 'react'
import { mount } from 'enzyme'
import SearchInput from '../Search/SearchInput'

describe('SearchInput', () => {
  let props
  let mountedSearchInput
  const searchInput = () => {
    if (!mountedSearchInput) {
      mountedSearchInput = mount(<SearchInput {...props} />)
    }

    return mountedSearchInput
  }

  beforeEach(() => {
    props = {
      formIdentifier: 'Identifier',
      value: 'Berlin',
      displayValue: 'Berlin',
      onClick: jest.fn(),
      label: 'Von'
    }

    mountedSearchInput = undefined
  })

  it('always renders a wrapper div', () => {
    const divs = searchInput().find('div')
    expect(divs.length).toBe(1)
  })

  it('passes to onClick callback on to the wrapper', () => {
    const wrapper = searchInput()
      .find('div')
      .first()
    expect(wrapper.props().onClick).toEqual(props.onClick)
  })

  it('passes the label text on', () => {
    const wrapper = searchInput()
      .find('div')
      .first()

    expect(wrapper.text()).toMatch(props.label)
  })

  it('always renders an input', () => {
    const wrapper = searchInput()
      .find('div')
      .first()
    const inputs = wrapper.find('input')
    expect(inputs.length).toBe(1)
  })

  describe('input', () => {
    let input

    beforeEach(() => {
      input = searchInput()
        .find('div')
        .first()
        .find('input')
        .first()
    })

    it('passes the value on to the input', () => {
      expect(input.props().value).toEqual(props.value)
    })

    it('passes the formIdentifier on to the input', () => {
      expect(input.props().name).toEqual(props.formIdentifier)
    })
  })
})
