import { useState, useRef } from 'react'
import { useArray } from 'react-hanger'
import getNeighborSeat from '../lib/getNeighborSeat'
import normalizeBus from '../lib/normalizeBus'

// Transforms the bus-json from rails into a more practical format for JS. Also
// includes state to make the SeatPlan dynamic
export default function useBus({ bus: busData, hasBonusSeat }) {
  const bus = useRef(normalizeBus(busData))

  const selectedSeats = useArray(() => {
    return busData.selected_seats.map(seat => {
      const seatId = seat.row + seat.seat
      return bus.current.seats[seatId]
    })
  })

  const [activeLevel, setActiveLevel] = useState(() => {
    if (busData.selected_seats.length === 0) return 'upstairs'
    const [lastSeat] = busData.selected_seats.slice(-1)
    return lastSeat.level
  })

  const [bonusSeat, setBonusSeat] = useState(() => {
    if (busData.selected_seats.length === 0) return undefined
    if (!hasBonusSeat) return undefined

    const [lastSeat] = busData.selected_seats.slice(-1)
    const lastSeatId = lastSeat.row + lastSeat.seat
    const seat = bus.current.seats[lastSeatId]
    return getBookableNeighborSeat(seat)
  })

  function getBookableNeighborSeat(seat) {
    const neighborSeat = getNeighborSeat(seat, bus.current.seats)
    if (neighborSeat.type !== 'seat') return undefined
    if (neighborSeat.booked) return undefined
    return neighborSeat
  }

  return {
    selectedSeats,
    activeLevel: {
      value: activeLevel,
      set(level) {
        setActiveLevel(level)
      }
    },
    bonusSeat: {
      value: bonusSeat,
      setForSeat(seat) {
        const bookableNeighborSeat = getBookableNeighborSeat(seat)
        setBonusSeat(bookableNeighborSeat)
      },
      clear() {
        setBonusSeat(undefined)
      }
    },
    levels: {
      basement: bus.current.basement,
      upstairs: bus.current.upstairs
    },
    seats: bus.current.seats
  }
}
