import React from 'react'

function DriverSvg(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47 59.1" {...props}>
      <g fill="#979797">
        <path
          d="M9.7 17.1h27.6c4.2 0 7.7 3.4 7.7 7.7v26.7c0 4.2-3.4 7.7-7.7 7.7H9.7c-4.2 0-7.7-3.4-7.7-7.7V24.8c0-4.3 3.4-7.7 7.7-7.7z"
          opacity=".503"
          fillRule="evenodd"
          clipRule="evenodd"
        />
        <path d="M23.5 0C10.5 0 0 10.5 0 23.5S10.5 47 23.5 47 47 36.5 47 23.5 36.5 0 23.5 0zm0 6.1c7.8 0 15.8 4.7 18.3 11.3H5.1C7.6 10.8 15.6 6.1 23.5 6.1zm0 20.9c-1.7 0-3-1.4-3-3 0-1.7 1.4-3 3-3h.5c1.7 0 2.6 1.4 2.6 3-.1 1.6-1.5 3-3.1 3zM4.4 22.6c8.6 0 15.5 7.8 15.7 17.4-9-1.4-15.7-8.6-15.7-17.4zM27 40c.1-9.6 7.1-17.4 15.7-17.4-.1 8.8-6.8 16-15.7 17.4z" />
      </g>
    </svg>
  )
}

export default DriverSvg
