import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styles from './__styles__/InputClearanceButton.module.sass'
import CloseSvg from './CloseSvg'

class InputClearanceButton extends PureComponent {
  render() {
    const { onClick } = this.props
    return (
      <button className={styles.button} onClick={onClick}>
        <CloseSvg className={styles.icon} />
      </button>
    )
  }
}

InputClearanceButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
}

export default InputClearanceButton
