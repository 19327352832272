import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import I18n from 'i18n-js'

import styles from './__styles__/Legend.module.sass'
import InfoSvg from './svgs/InfoSvg'
import Seat from './Seat'

function Legend({ isMulticol, selectedLabel = '17D', hasBonusSeat }) {
  const seat = {
    id: selectedLabel,
    direction: 'in_travel',
    type: 'seat',
    isBookable: true
  }

  const itemClasses = classnames(styles.item, {
    [styles.itemHalf]: isMulticol
  })

  return (
    <div className={styles.wrapper}>
      <div className={itemClasses}>
        <div className={styles.seat}>
          <Seat isActive seat={{ ...seat, booked: 'Ticket' }} />
        </div>
        <div className={styles.seatLabel}>{I18n.t('js.legend.seat')}</div>
      </div>
      {hasBonusSeat && (
        <div className={itemClasses}>
          <div className={styles.seat}>
            <Seat isBonus seat={{ ...seat, booked: 'Bonus' }} />
          </div>
          <div className={styles.seatLabel}>{I18n.t('js.legend.bonus')}</div>
        </div>
      )}
      <div className={itemClasses}>
        <div className={styles.seat}>
          <Seat seat={{ ...seat, booked: 'Ticket', isBookable: false }} />
        </div>
        <div className={styles.seatLabel}>
          {I18n.t('js.legend.not_available')}
        </div>
      </div>
      <div className={itemClasses}>
        <div className={styles.seat}>
          <Seat seat={{ ...seat }} />
        </div>
        <div className={styles.seatLabel}>{I18n.t('js.legend.available')}</div>
      </div>
      <div className={itemClasses}>
        <div className={styles.seat}>
          <Seat seat={{ ...seat, type: 'wheelchair' }} />
        </div>
        <div className={styles.seatLabel}>{I18n.t('js.legend.wheelchair')}</div>
        <a
          href="https://www.pinkbus.de/service/reisen-mit-behinderung/"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.info}
        >
          <InfoSvg />
        </a>
      </div>
      <div className={styles.sep} />
      <div className={styles.item}>
        {I18n.t('js.legend.kids')}
        <a
          href="https://www.pinkbus.de/service/reisen-mit-kind/"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.info}
        >
          <InfoSvg />
        </a>
      </div>
    </div>
  )
}

Legend.propTypes = {
  isMulticol: PropTypes.bool,
  selectedLabel: PropTypes.string,
  hasBonusSeat: PropTypes.bool
}

export default Legend
