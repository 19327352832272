export default function normalizeBus({
  upstairs,
  basement,
  doors,
  selected_seats: selectedSeats
}) {
  const normalizeLevel = normalizeLevelGen({ selectedSeats, doors })
  const [basementRows, basementSeats] = normalizeLevel(basement, 'basement')
  const [upstairsRows, upstairsSeats] = normalizeLevel(upstairs, 'upstairs')

  return {
    basement: basementRows,
    upstairs: upstairsRows,
    seats: { ...basementSeats, ...upstairsSeats }
  }
}

const normalizeLevelGen = ({ selectedSeats, doors }) => (rows, levelName) => {
  const allSeats = {}
  const rowsWithIds = rows.map(([rowNumber, seats]) => {
    // Objects have no sort, so we sort it here just to be on the safe side.
    const seatLetters = Object.keys(seats).sort()

    const seatsInRow = seatLetters.map(seatLetter => {
      const seatData = seats[seatLetter]
      const isBookedByCurrentUser = selectedSeats.some(
        s =>
          s.level === levelName && s.row === rowNumber && s.seat === seatLetter
      )
      const seatId = rowNumber + seatLetter
      const seat = {
        id: seatId,
        row: rowNumber,
        level: levelName,
        seat: seatLetter,
        isBookable: !seatData.booked || isBookedByCurrentUser,
        ...seatData
      }
      allSeats[seatId] = seat
      return seat
    })

    return {
      row: rowNumber,
      seats: seatsInRow,
      hasDoor: doors.includes(rowNumber)
    }
  })

  return [rowsWithIds, allSeats]
}
