import React from 'react'

function InfoSvg(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" {...props}>
      <path d="M6 1c2.757 0 5 2.243 5 5s-2.243 5-5 5-5-2.243-5-5 2.243-5 5-5zm0-1a6 6 0 1 0 0 12A6 6 0 0 0 6 0zm.5 9h-1V5h1v4zM6 2.875a.625.625 0 1 1 0 1.25.625.625 0 0 1 0-1.25z" />
    </svg>
  )
}

export default InfoSvg
