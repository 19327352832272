import React from 'react'
import PropTypes from 'prop-types'

import ColDriver from './ColDriver'
import ColCorridor from './ColCorridor'
import ColEmpty from './ColEmpty'
import ColSeat from './ColSeat'
import ColStairs from './ColStairs'
import ColTable from './ColTable'
import ColWC from './ColWC'

function BusCol({ seat, isActive, isBonus, onClick }) {
  if (seat.type === 'empty') return <ColEmpty />
  if (seat.type === 'table') return <ColTable />
  if (seat.type === 'corridor') return <ColCorridor number={seat.row} />
  if (seat.type === 'wc') return <ColWC />
  if (seat.type === 'driver') return <ColDriver />
  if (seat.type === 'stairs')
    return <ColStairs isReversed={seat.direction === 'in_opposition'} />

  function handleSeatClick() {
    onClick(seat)
  }

  return (
    <ColSeat
      seat={seat}
      isActive={isActive}
      isBonus={isBonus}
      onClick={handleSeatClick}
    />
  )
}

BusCol.propTypes = {
  seat: PropTypes.shape({
    id: PropTypes.string.isRequired,
    row: PropTypes.number.isRequired,
    booked: PropTypes.oneOf(['Ticket', 'Bonus', 'Admin']),
    seat: PropTypes.string.isRequired,
    direction: PropTypes.oneOf(['none', 'in_travel', 'in_opposition'])
      .isRequired,
    type: PropTypes.oneOf([
      'seat',
      'stairs',
      'empty',
      'table',
      'corridor',
      'wheelchair',
      'driver',
      'wc'
    ]).isRequired
  }).isRequired,
  isActive: PropTypes.bool,
  isBonus: PropTypes.bool,
  onClick: PropTypes.func.isRequired
}

export default BusCol
