import React from 'react'
import PropTypes from 'prop-types'
import BusRow from './BusRow'
import BusCol from './BusCol'

import styles from './__styles__/BusLevel.module.sass'

function BusLevel({ rows, selectedSeats, bonusSeat, onSeatClick, onBusClick }) {
  function isSeatSelected(seat) {
    return selectedSeats.some(activeSeat => activeSeat.id === seat.id)
  }

  function isSeatBonus(seat) {
    if (!bonusSeat) return false
    return bonusSeat.id === seat.id
  }

  const renderIdentifier = item => {
    return <div className={styles.gridHeader}>{item}</div>
  }

  const renderRowItem = seat => {
    const isActive = isSeatSelected(seat)
    const isBonus = isSeatBonus(seat)

    return (
      <BusCol
        seat={seat}
        isActive={isActive}
        isBonus={isBonus}
        onClick={onSeatClick}
      />
    )
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.bus} onClick={onBusClick}>
        <table className={styles.grid}>
          <tbody>
            <BusRow
              items={['A', 'B', 'C', 'D', 'E']}
              renderItem={renderIdentifier}
            />
            {rows.map(row => (
              <BusRow
                key={row.row}
                items={row.seats}
                renderItem={renderRowItem}
                rowClassName={row.hasDoor ? styles.door : ''}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

BusLevel.propTypes = {
  onBusClick: PropTypes.func,
  onSeatClick: PropTypes.func.isRequired,
  selectedSeats: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string.isRequired })
  ).isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      row: PropTypes.number.isRequired,
      seats: PropTypes.arrayOf(
        PropTypes.shape({ id: PropTypes.string.isRequired })
      ).isRequired
    })
  ).isRequired,
  bonusSeat: PropTypes.shape({ id: PropTypes.string.isRequired })
}

export default BusLevel
