import React from 'react'

class WheelchairSvg extends React.PureComponent {
  render() {
    return (
      <svg
        viewBox="0 0 77 88.77"
        xmlns="http://www.w3.org/2000/svg"
        {...this.props}
      >
        <path
          d="m70.84 88.77h-64.68a6.17 6.17 0 0 1 -6.16-6.16v-11.77c0-13.92 21.06-25.13 38.5-25.13 15.5 0 38.5 10.56 38.5 25.13v11.77a6.17 6.17 0 0 1 -6.16 6.16zm-32.34-36.62c-15.28 0-32.06 9.79-32.06 18.69v11.77l64.4-.28-.28-11.49c0-9.27-18.65-18.69-32.06-18.69zm0-10.43a20.86 20.86 0 1 1 20.86-20.86 20.89 20.89 0 0 1 -20.86 20.86zm0-35.28a14.42 14.42 0 1 0 14.42 14.42 14.44 14.44 0 0 0 -14.42-14.42z"
          fill="currentColor"
        />
      </svg>
    )
  }
}

export default WheelchairSvg
