import React from 'react'
import I18n from 'i18n-js'

import ArrowSvg from './svgs/ArrowSvg'
import styles from './__styles__/DirectionIndicator.module.sass'

function DirectionIndicator() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.text}>{I18n.t('js.seatplan.direction')}</div>
      <div className={styles.icon}>
        <ArrowSvg />
      </div>
    </div>
  )
}

export default DirectionIndicator
