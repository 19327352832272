import React from 'react'

function LetterASvg(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.2 34" {...props}>
      <path
        fill="currentColor"
        d="M30.2 34h-7.3l-2.5-7.2H9.7L7.3 34H0L12.4 0h5.5l12.3 34zM11.8 20.5h6.6l-3.3-9.9-3.3 9.9z"
      />
    </svg>
  )
}

export default LetterASvg
