import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import Media from 'react-media'
import classnames from 'classnames'

import useBus from './hooks/useBus'
import BusLevels from './BusLevels'
import DirectionIndicator from './DirectionIndicator'
import BusLevelTabs from './BusLevelTabs'
import SelectedSeatsInputs from './SelectedSeatsInputs'
import Legend from './Legend'
import BottomSheet from './BottomSheet'
import styles from './__styles__/SeatPlan.module.sass'

export const ViewContext = React.createContext('user')

function SeatPlan({
  bus,
  nameSpace,
  targetPage,
  rideId,
  hasBonusSeat,
  numberOfSeats = 0,
  viewAs = 'user'
}) {
  const {
    selectedSeats,
    activeLevel,
    bonusSeat,
    levels,
    seats: _seats // all seats by id. currently unused, but here just in case someone needs it sometime
  } = useBus({ bus, hasBonusSeat })

  function handleTabChange(level) {
    activeLevel.set(level)
  }

  function handleBusLevelChange(level) {
    activeLevel.set(level)
  }

  function handleSeatClick(clickedSeat) {
    const unselectSeat = selectedSeats.value.find(seat => {
      return seat.id === clickedSeat.id
    })

    // Batch updates since we're doing a few state modifications in here
    ReactDOM.unstable_batchedUpdates(() => {
      if (unselectSeat) {
        selectedSeats.removeById(unselectSeat.id)
        if (bonusSeat.value) bonusSeat.clear()
        return
      }

      if (hasBonusSeat) {
        bonusSeat.setForSeat(clickedSeat)
      }

      // Remove oldest seat when selecting too many seats
      if (selectedSeats.value.length >= numberOfSeats) {
        selectedSeats.removeIndex(selectedSeats.value.length - 1)
      }

      selectedSeats.add(clickedSeat)
    })
  }

  return (
    <div className={styles.wrapper}>
      <ViewContext.Provider value={viewAs}>
        <Media query={{ maxWidth: '37.5rem' }}>
          {matches => (
            <div>
              <BusLevelTabs
                levels={['basement', 'upstairs']}
                activeLevel={activeLevel.value}
                onChange={handleTabChange}
                isClickable={matches}
              />
              <div className={styles.directionWrapper}>
                <DirectionIndicator />
              </div>
              <BusLevels
                activeLevel={activeLevel.value}
                onLevelChange={handleBusLevelChange}
                onSeatClick={handleSeatClick}
                selectedSeats={viewAs === 'driver' ? [] : selectedSeats.value}
                bonusSeat={bonusSeat.value}
                isMobile={matches}
                levels={[
                  { level: 'basement', rows: levels.basement },
                  { level: 'upstairs', rows: levels.upstairs }
                ]}
              />
            </div>
          )}
        </Media>
        {viewAs === 'user' && (
          <Media query={{ maxWidth: '80rem' }}>
            {matches => (
              <div
                className={classnames({
                  [styles.legendAside]: !matches,
                  [styles.legendBelow]: matches
                })}
              >
                <Legend
                  isMulticol={matches}
                  selectedLabel={
                    selectedSeats.value.length > 0
                      ? selectedSeats.value[0].id
                      : undefined
                  }
                  hasBonusSeat={hasBonusSeat}
                />
              </div>
            )}
          </Media>
        )}
        {viewAs !== 'driver' && (
          <SelectedSeatsInputs
            selectedSeats={selectedSeats.value}
            nameSpace={nameSpace}
            rideId={rideId}
          />
        )}
        {viewAs === 'user' && (
          <div className={styles.bottomFixed}>
            <BottomSheet
              numberOfSeats={numberOfSeats}
              targetPage={targetPage}
              selectedSeats={selectedSeats.value}
            />
          </div>
        )}
      </ViewContext.Provider>
    </div>
  )
}

SeatPlan.propTypes = {
  bus: PropTypes.shape({
    name: PropTypes.string.isRequired,
    basement: PropTypes.array.isRequired,
    upstairs: PropTypes.array.isRequired,
    selected_seats: PropTypes.array.isRequired
  }).isRequired,
  numberOfSeats: PropTypes.number,
  nameSpace: PropTypes.string,
  targetPage: PropTypes.string,
  hasBonusSeat: PropTypes.bool,
  rideId: PropTypes.number,
  hasBottomContainer: PropTypes.bool,
  viewAs: PropTypes.oneOf(['user', 'driver', 'admin'])
}

export default SeatPlan
