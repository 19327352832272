import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import SearchInput from './SearchInput'
import InputClearanceButton from './InputClearanceButton'
import styles from './__styles__/ClearableSearchInput.module.sass'

class ClearableSearchInput extends PureComponent {
  render() {
    const { onClearance, ...rest } = this.props
    return (
      <div className={styles.wrapper}>
        <SearchInput {...rest} />
        {onClearance && <InputClearanceButton onClick={onClearance} />}
      </div>
    )
  }
}

ClearableSearchInput.propTypes = {
  onClearance: PropTypes.func
}

export default ClearableSearchInput
