import React from 'react'

import WCSvg from './svgs/WCSvg'
import styles from './__styles__/ColWC.module.sass'

function ColWC() {
  return (
    <div className={styles.box}>
      <WCSvg className={styles.icon} />
      <div className={styles.text}>WC</div>
    </div>
  )
}

export default ColWC
