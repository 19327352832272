import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import QrReader from 'react-qr-reader'
import fetch from 'unfetch'
import CustomViewFinder from './CustomViewFinder'
import ViewFinderStyles from './__styles__/ViewFinder.module.sass'

class QrcodeReader extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isError: false,
      isSuccess: false,
      previousData: null,
      previousCustomer: null,
      successMessage: null,
      errorMessage: null
    }
  }

  handleScan = data => {
    if (!data || data === this.state.previousData) {
      return
    }

    if (this.timeout) {
      clearTimeout(this.timeout)
      this.timeout = null
    }

    this.setState({
      previousData: data,
      isError: false,
      isSuccess: true,
      successMessage: null,
      errorMessage: null
    })

    const info = data.split('-')

    const [bookingCode, ticketId] = info

    if (info.length === 2 && bookingCode.length === 8 && ticketId.length > 0) {
      this.postSuccess(ticketId)

      this.timeout = setTimeout(() => {
        this.setState({
          isError: false,
          isSuccess: false,
          previousCustomer: null,
          successMessage: null,
          errorMessage: null
        })
      }, 15000)
    }
  }

  postSuccess = ticketId => {
    fetch(`/driver/rides/${this.props.rideId}/checkins/${ticketId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        if (response.status === 200) {
          this.setState({
            isSuccess: true,
            isError: false,
            successMessage: 'Ticket erfolgreich entwertet.',
            errorMessage: null
          })
        } else if (response.status === 403) {
          this.setState({
            isError: true,
            isSuccess: false,
            errorMessage: 'Dieses Ticket wurde bereits entwertet.'
          })
        } else if (response.status === 404) {
          this.setState({
            isError: true,
            isSuccess: false,
            errorMessage: 'Dieses Ticket konnte nicht gefunden werden.'
          })
        }

        return response.json()
      })
      .then(response => {
        this.setState({
          previousCustomer: response.passenger_full_name
        })
      })
  }

  handleError = err => {
    this.setState({ isError: true })
    console.error(err)
  }

  render() {
    const { previousCustomer, successMessage, errorMessage } = this.state

    const containerClasses = classNames(ViewFinderStyles.container, {
      [ViewFinderStyles.error]: this.state.isError,
      [ViewFinderStyles.success]: this.state.isSuccess
    })
    return (
      <div className={containerClasses}>
        {previousCustomer && (
          <div className={ViewFinderStyles.message}>{previousCustomer}</div>
        )}

        {successMessage && (
          <div className={ViewFinderStyles.message}>{successMessage}</div>
        )}
        {errorMessage && (
          <div className={ViewFinderStyles.message}>{errorMessage}</div>
        )}

        <QrReader
          delay={50}
          onError={this.handleError}
          onScan={this.handleScan}
          customViewFinder={<CustomViewFinder />}
        />
      </div>
    )
  }
}

QrcodeReader.propTypes = {
  rideId: PropTypes.number.isRequired
}

export default QrcodeReader
