import React from 'react'
import PropTypes from 'prop-types'
import styles from './__styles__/ColCorridor.module.sass'

function ColCorridor({ number }) {
  // don't show hidden rows (rows numbers with fractions)
  return <div className={styles.corridor}>{number % 1 === 0 && number}</div>
}

ColCorridor.propTypes = {
  number: PropTypes.number.isRequired
}

export default ColCorridor
