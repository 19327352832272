import React from 'react'
import PropTypes from 'prop-types'
import I18n from 'i18n-js'

class NumberOfPassengers extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      count: this.props.value
    }
  }

  handleIncreaseClick() {
    if (this.state.count < this.props.maxCount) {
      this.setState({ count: this.state.count + 1 })
    }
  }

  handleChange(event) {
    this.setState({ count: event.target.value })
  }

  handleDecreaseClick() {
    if (this.state.count > 1) {
      this.setState({ count: this.state.count - 1 })
    }
  }

  render() {
    const options = [...Array(this.props.maxCount + 1).keys()].map(number => {
      return <option key={number}>{number}</option>
    })

    return (
      <div className="aSearchFormInput asPassenger">
        <span className="aSearchFormInput-label">
          {I18n.t('js.search.passenger.label')}
        </span>
        <div className="aSearchFormInput-input">
          <div
            id="decrease-passenger"
            className="aSearchFormInput-button"
            onClick={() => this.handleDecreaseClick()}
          >
            -
          </div>
          <select
            id="passenger-count"
            name="passengers"
            onChange={event => this.handleChange(event)}
            value={this.state.count}
          >
            {options}
          </select>
          <div className="aSearchFormInput-value">{this.state.count}</div>
          <div
            id="increase-passenger"
            className="aSearchFormInput-button"
            onClick={() => this.handleIncreaseClick()}
          >
            +
          </div>
        </div>
        <label className="aSearchFormInput-label" htmlFor="passenger-count">
          {I18n.t('js.search.passenger.subline')}
        </label>
      </div>
    )
  }
}

NumberOfPassengers.propTypes = {
  value: PropTypes.number.isRequired,
  maxCount: PropTypes.number.isRequired
}

NumberOfPassengers.defaultProps = {
  value: 1,
  maxCount: 16
}

export default NumberOfPassengers
