import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

class LocationsList extends PureComponent {
  render() {
    return (
      <div className="aLocationsList">{this.props.items.map(item => item)}</div>
    )
  }
}

LocationsList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.node).isRequired
}

export default LocationsList
