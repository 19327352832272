const rowsArrayFixture = [
  {
    row: 16.1,
    seats: [
      {
        id: '16.1A',
        row: 16.1,
        level: 'basement',
        seat: 'A',
        direction: 'none',
        type: 'driver',
        colspan: 2
      },
      {
        id: '16.1C',
        row: 16.1,
        level: 'basement',
        seat: 'C',
        direction: 'none',
        type: 'empty'
      },
      {
        id: '16.1D',
        row: 16.1,
        level: 'basement',
        seat: 'D',
        direction: 'none',
        type: 'empty'
      },
      {
        id: '16.1E',
        row: 16.1,
        level: 'basement',
        seat: 'E',
        direction: 'none',
        type: 'empty'
      }
    ]
  },
  {
    row: 17,
    seats: [
      {
        id: '17A',
        row: 17,
        level: 'basement',
        seat: 'A',
        direction: 'in_travel',
        type: 'stairs',
        colspan: 2,
        rowspan: 2
      },
      {
        id: '17C',
        row: 17,
        level: 'basement',
        seat: 'C',
        direction: 'none',
        type: 'corridor'
      },
      {
        id: '17D',
        row: 17,
        level: 'basement',
        seat: 'D',
        direction: 'in_travel',
        type: 'seat'
      },
      {
        id: '17E',
        row: 17,
        level: 'basement',
        seat: 'E',
        direction: 'in_travel',
        type: 'seat'
      }
    ]
  },
  {
    row: 17.1,
    seats: [
      {
        id: '17.1C',
        row: 17.1,
        level: 'basement',
        seat: 'C',
        direction: 'none',
        type: 'corridor'
      },
      {
        id: '17.1D',
        row: 17.1,
        level: 'basement',
        seat: 'D',
        direction: 'none',
        type: 'empty'
      },
      {
        id: '17.1E',
        row: 17.1,
        level: 'basement',
        seat: 'E',
        direction: 'none',
        type: 'empty'
      }
    ]
  },
  {
    row: 18,
    seats: [
      {
        id: '18A',
        row: 18,
        level: 'basement',
        seat: 'A',
        direction: 'in_opposition',
        type: 'seat'
      },
      {
        id: '18B',
        row: 18,
        level: 'basement',
        seat: 'B',
        direction: 'in_opposition',
        type: 'seat'
      },
      {
        id: '18C',
        row: 18,
        level: 'basement',
        seat: 'C',
        direction: 'none',
        type: 'corridor'
      },
      {
        id: '18D',
        row: 18,
        level: 'basement',
        seat: 'D',
        direction: 'in_opposition',
        type: 'seat'
      },
      {
        id: '18E',
        row: 18,
        level: 'basement',
        seat: 'E',
        direction: 'in_opposition',
        type: 'seat'
      }
    ]
  },
  {
    row: 18.1,
    seats: [
      {
        id: '18.1A',
        row: 18.1,
        level: 'basement',
        seat: 'A',
        direction: 'none',
        type: 'table',
        colspan: 2
      },
      {
        id: '18.1C',
        row: 18.1,
        level: 'basement',
        seat: 'C',
        direction: 'none',
        type: 'corridor'
      },
      {
        id: '18.1D',
        row: 18.1,
        level: 'basement',
        seat: 'D',
        direction: 'none',
        type: 'table',
        colspan: 2
      }
    ]
  },
  {
    row: 19,
    seats: [
      {
        id: '19A',
        row: 19,
        level: 'basement',
        seat: 'A',
        direction: 'in_travel',
        type: 'seat'
      },
      {
        id: '19B',
        row: 19,
        level: 'basement',
        seat: 'B',
        direction: 'in_travel',
        type: 'seat'
      },
      {
        id: '19C',
        row: 19,
        level: 'basement',
        seat: 'C',
        direction: 'none',
        type: 'corridor'
      },
      {
        id: '19D',
        row: 19,
        level: 'basement',
        seat: 'D',
        direction: 'in_travel',
        type: 'seat'
      },
      {
        id: '19E',
        row: 19,
        level: 'basement',
        seat: 'E',
        direction: 'in_travel',
        type: 'seat'
      }
    ]
  },
  {
    row: 20,
    seats: [
      {
        id: '20A',
        row: 20,
        level: 'basement',
        seat: 'A',
        direction: 'in_travel',
        type: 'seat'
      },
      {
        id: '20B',
        row: 20,
        level: 'basement',
        seat: 'B',
        direction: 'in_travel',
        type: 'seat'
      },
      {
        id: '20C',
        row: 20,
        level: 'basement',
        seat: 'C',
        direction: 'none',
        type: 'corridor'
      },
      {
        id: '20D',
        row: 20,
        level: 'basement',
        seat: 'D',
        direction: 'in_travel',
        type: 'seat'
      },
      {
        id: '20E',
        row: 20,
        level: 'basement',
        seat: 'E',
        direction: 'in_travel',
        type: 'seat'
      }
    ]
  },
  {
    row: 21,
    seats: [
      {
        id: '21A',
        row: 21,
        level: 'basement',
        seat: 'A',
        direction: 'in_travel',
        type: 'seat'
      },
      {
        id: '21B',
        row: 21,
        level: 'basement',
        seat: 'B',
        direction: 'in_travel',
        type: 'seat',
        booked: 'Ticket'
      },
      {
        id: '21C',
        row: 21,
        level: 'basement',
        seat: 'C',
        direction: 'none',
        type: 'corridor'
      },
      {
        id: '21D',
        row: 21,
        level: 'basement',
        seat: 'D',
        direction: 'none',
        type: 'empty'
      },
      {
        id: '21E',
        row: 21,
        level: 'basement',
        seat: 'E',
        direction: 'in_travel',
        type: 'wheelchair'
      }
    ]
  },
  {
    row: 22,
    seats: [
      {
        id: '22A',
        row: 22,
        level: 'basement',
        seat: 'A',
        direction: 'none',
        type: 'wc',
        colspan: 2
      },
      {
        id: '22C',
        row: 22,
        level: 'basement',
        seat: 'C',
        direction: 'none',
        type: 'corridor'
      },
      {
        id: '22D',
        row: 22,
        level: 'basement',
        seat: 'D',
        direction: 'none',
        type: 'empty'
      },
      {
        id: '22E',
        row: 22,
        level: 'basement',
        seat: 'E',
        direction: 'in_travel',
        type: 'wheelchair'
      }
    ]
  },
  {
    row: 22.1,
    seats: [
      {
        id: '22.1A',
        row: 22.1,
        level: 'basement',
        seat: 'A',
        direction: 'none',
        type: 'empty'
      },
      {
        id: '22.1B',
        row: 22.1,
        level: 'basement',
        seat: 'B',
        direction: 'none',
        type: 'empty'
      },
      {
        id: '22.1C',
        row: 22.1,
        level: 'basement',
        seat: 'C',
        direction: 'none',
        type: 'corridor'
      },
      {
        id: '22.1D',
        row: 22.1,
        level: 'basement',
        seat: 'D',
        direction: 'in_opposition',
        type: 'stairs',
        colspan: 2,
        rowspan: 2
      }
    ]
  },
  {
    row: 22.2,
    seats: [
      {
        id: '22.2A',
        row: 22.2,
        level: 'basement',
        seat: 'A',
        direction: 'none',
        type: 'empty'
      },
      {
        id: '22.2B',
        row: 22.2,
        level: 'basement',
        seat: 'B',
        direction: 'none',
        type: 'empty'
      },
      {
        id: '22.2C',
        row: 22.2,
        level: 'basement',
        seat: 'C',
        direction: 'none',
        type: 'corridor'
      }
    ]
  },
  {
    row: 22.3,
    seats: [
      {
        id: '22.3A',
        row: 22.3,
        level: 'basement',
        seat: 'A',
        direction: 'none',
        type: 'empty',
        colspan: 5
      }
    ]
  },
  {
    row: 22.4,
    seats: [
      {
        id: '22.4A',
        row: 22.4,
        level: 'basement',
        seat: 'A',
        direction: 'none',
        type: 'empty',
        colspan: 5
      }
    ]
  },
  {
    row: 22.5,
    seats: [
      {
        id: '22.5A',
        row: 22.5,
        level: 'basement',
        seat: 'A',
        direction: 'none',
        type: 'empty',
        colspan: 5
      }
    ]
  },
  {
    row: 22.6,
    seats: [
      {
        id: '22.6A',
        row: 22.6,
        level: 'basement',
        seat: 'A',
        direction: 'none',
        type: 'empty',
        colspan: 5
      }
    ]
  },
  {
    row: 22.7,
    seats: [
      {
        id: '22.7A',
        row: 22.7,
        level: 'basement',
        seat: 'A',
        direction: 'none',
        type: 'empty',
        colspan: 5
      }
    ]
  }
]

export default rowsArrayFixture
