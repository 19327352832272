import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import StairsSvg from './svgs/StairsSvg'
import styles from './__styles__/ColStairs.module.sass'

function ColStairs({ isReversed }) {
  return (
    <div
      className={classnames(styles.item, {
        [styles.reversed]: isReversed
      })}
    >
      <StairsSvg className={styles.icon} />
    </div>
  )
}

ColStairs.propTypes = {
  isReversed: PropTypes.bool
}

export default ColStairs
