import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

class LocationsListItem extends PureComponent {
  className = () => {
    const baseClassName = 'aLocationsList-item'
    const { isDisabled } = this.props

    return isDisabled ? baseClassName + ' asDisabled' : baseClassName
  }

  handleClick = () => {
    return this.props.isDisabled ? null : this.props.onClick
  }

  render() {
    const { value, isDisabled } = this.props

    return (
      <div
        id={`location_${value.toLowerCase()}`}
        role="button"
        aria-disabled={isDisabled}
        tabIndex="0"
        onClick={this.handleClick()}
        className={this.className()}
      >
        {value}
      </div>
    )
  }
}

LocationsListItem.propTypes = {
  value: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool
}

export default LocationsListItem
