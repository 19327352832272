import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './__styles__/Seat.module.sass'
import PersonSvg from './svgs/PersonSvg'
import LetterASvg from './svgs/LetterASvg'
import StarSvg from './svgs/StarSvg'
import CrossSvg from './svgs/CrossSvg'
import WheelchairSvg from './svgs/WheelchairSvg'
import { ViewContext } from './SeatPlan'

function Seat({ seat, isActive, isBonus, isFocused }) {
  const viewAs = useContext(ViewContext)
  const isDriver = viewAs === 'driver'
  const isAdmin = viewAs === 'admin'
  const isBooked = !seat.isBookable

  const classes = classnames(styles.box, {
    [styles.disableBox]: isBooked,
    [styles.activeBox]: isActive || isBonus,
    [styles.focusBox]: !isActive && isFocused,
    [styles.reverseBox]: seat.direction === 'in_opposition'
  })

  // Candidate for refactoring, when requirements are a bit clearer
  function getIcon() {
    // driver icons
    if (isDriver && isBooked && seat.booked === 'Admin') return LetterASvg
    if (isDriver && isBooked && seat.booked === 'Bonus') return StarSvg
    if (isDriver && isBooked && seat.type === 'wheelchair') return WheelchairSvg
    if (isDriver && isBooked) return PersonSvg

    // admin icons
    if (isAdmin && isBooked && seat.booked === 'Admin') return LetterASvg
    if (isAdmin && isBooked && seat.booked === 'Bonus') return StarSvg
    if (isDriver && isBooked && seat.type === 'wheelchair') return WheelchairSvg
    if (isAdmin && isBooked) return PersonSvg

    // user icons
    if (isBooked) return CrossSvg
    if (seat.type === 'wheelchair') return WheelchairSvg
  }

  function getLabel() {
    // driver label
    if (isDriver && isBooked) return

    // user label
    if (isActive) return seat.id
    if (isBooked) return
  }

  const Icon = getIcon()

  return (
    <div className={classes}>
      <div className={styles.inner}>
        {!!Icon && (
          <div className={styles.iconContainer}>
            <Icon className={styles.icon} />
          </div>
        )}
        {getLabel()}
      </div>
    </div>
  )
}

Seat.propTypes = {
  seat: PropTypes.shape({
    id: PropTypes.string.isRequired,
    isBookable: PropTypes.bool.isRequired,
    booked: PropTypes.oneOf(['Ticket', 'Bonus', 'Admin']),
    type: PropTypes.oneOf(['seat', 'wheelchair']).isRequired,
    direction: PropTypes.oneOf(['in_travel', 'in_opposition']).isRequired
  }).isRequired,
  isFocused: PropTypes.bool,
  isActive: PropTypes.bool,
  isBonus: PropTypes.bool
}

export default Seat
