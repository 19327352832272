import React from 'react'

function ArrowSvg(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31"
      height="34"
      viewBox="0 0 31 34"
      {...props}
    >
      <path
        fill="currentColor"
        d="M.5 15.59l3.856 3.915 8.422-8.492V33.5h5.444V11.013l8.422 8.492 3.856-3.914L15.5.5z"
      />
    </svg>
  )
}

export default ArrowSvg
