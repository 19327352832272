import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

class PrimaryButton extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      showLoadingIndicator: false
    }
  }

  handleClick = () => {
    this.setState({ showLoadingIndicator: this.props.hasLoadingIndicator })
  }

  render() {
    const { isDisabled, children } = this.props

    return (
      <button
        className={classNames('aButton', {
          isLoading: this.state.showLoadingIndicator
        })}
        disabled={isDisabled}
        onClick={this.handleClick}
      >
        {children}
      </button>
    )
  }
}

PrimaryButton.propTypes = {
  children: PropTypes.node.isRequired,
  isDisabled: PropTypes.bool,
  hasLoadingIndicator: PropTypes.bool
}

PrimaryButton.defaultProps = {
  hasLoadingIndicator: false
}

export default PrimaryButton
