import React from 'react'

import DriverSvg from './svgs/DriverSvg'
import styles from './__styles__/ColDriver.module.sass'

function ColDriver() {
  return (
    <div className={styles.wrapper}>
      <DriverSvg className={styles.icon} />
    </div>
  )
}

export default ColDriver
