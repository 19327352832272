import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import I18n from 'i18n-js'
import SearchDatesContainer from './SearchDatesContainer'
import SearchDestinationsContainer from './SearchDestinationsContainer'
import NumberOfPassengers from './NumberOfPassengers'
import PrimaryButton from '../common/PrimaryButton'

class SearchForm extends PureComponent {
  constructor(props) {
    super(props)

    const {
      departureDate,
      arrivalDate,
      startCity,
      endCity,
      cities,
      requiredCity
    } = this.props

    // If start and end desitnations are provided from the controller, we
    // only have the slugs, not the whole city object
    const startDestination = cities.filter(city => city.slug == startCity)[0]
    const endDestination = cities.filter(city => city.slug == endCity)[0]
    this.state = {
      startCity: startDestination,
      endCity: endDestination,
      startDate: departureDate
        ? moment(departureDate)
        : moment(this.props.earliestDate),
      endDate: arrivalDate ? moment(arrivalDate) : undefined
    }
  }

  isFormValid = () => {
    const { startCity, endCity, startDate } = this.state
    return (
      startCity !== undefined &&
      endCity !== undefined &&
      startDate !== undefined
    )
  }

  handleEndCityChange = location => {
    this.setState({ endCity: location })
  }

  handleStartCityChange = location => {
    this.setState({ startCity: location })
  }

  handleStartDateChange = date => {
    const { endDate } = this.state

    if (endDate && moment(date).isSameOrAfter(endDate, 'day')) {
      this.setState({
        startDate: date,
        endDate: moment(date).add(1, 'days')
      })
      return
    }

    this.setState({ startDate: date })
  }

  handleEndDateChange = date => {
    this.setState({ endDate: date })
  }

  isDayBlocked = day => {
    const { startCity, endCity } = this.state

    if (day.isBefore('2019-11-05')) {
      return false
    }

    // We can't block days if we don't know the routes yet
    if (!startCity || !endCity) {
      return false
    }

    // if (
    //   (startCity.name === 'Berlin' && endCity.name === 'Düsseldorf') ||
    //   (startCity.name === 'Düsseldorf' && endCity.name === 'Berlin')
    // ) {
    //   return true
    // }

    // if (
    //   startCity.name === 'München' &&
    //   endCity.name === 'Berlin' &&
    //   // Tue, Wed & Thu will not be bookable in the future
    //   [2, 3, 4].includes(day.isoWeekday())
    // ) {
    //   return true
    // }

    return false
  }

  render() {
    const { cities, lines, earliestDate, latestDate, requiredCity } = this.props
    const { startCity, endCity, startDate, endDate } = this.state
    return (
      <div className="aSearchForm">
        <div className="aSearchForm-inputGroup asInput">
          <SearchDestinationsContainer
            cities={cities}
            lines={lines}
            startCity={startCity}
            endCity={endCity}
            requiredCity={requiredCity}
            onEndCityChange={this.handleEndCityChange}
            onStartCityChange={this.handleStartCityChange}
          />
        </div>
        <div className="aSearchForm-inputGroup asInput">
          <SearchDatesContainer
            earliestDate={moment(earliestDate)}
            latestDate={moment(latestDate)}
            startDate={startDate}
            endDate={endDate}
            requiredCity={requiredCity}
            onStartDateChange={this.handleStartDateChange}
            onEndDateChange={this.handleEndDateChange}
            isDayBlocked={this.isDayBlocked}
          />
        </div>
        <div className="aSearchForm-inputGroup">
          <NumberOfPassengers />
        </div>
        <div className="aSearchForm-inputGroup asButton">
          <PrimaryButton isDisabled={!this.isFormValid()}>
            {I18n.t('js.search.button_text')}
          </PrimaryButton>
        </div>
      </div>
    )
  }
}

SearchForm.propTypes = {
  cities: PropTypes.array.isRequired,
  lines: PropTypes.array,
  earliestDate: PropTypes.string,
  latestDate: PropTypes.object,
  startCity: PropTypes.string,
  endCity: PropTypes.string,
  requiredCity: PropTypes.string,
  departureDate: PropTypes.string,
  arrivalDate: PropTypes.string
}

SearchForm.defaultProps = {
  earliestDate: moment().add(1, 'days'),
  latestDate: moment().add(2, 'months'),
  requiredCity: 'weeze'
}

export default SearchForm
