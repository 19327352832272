import React from 'react'
import PropTypes from 'prop-types'
import I18n from 'i18n-js'
import PrimaryButton from '../common/PrimaryButton'
import styles from './__styles__/BottomSheet.module.sass'

function BottomSheet({ numberOfSeats, selectedSeats, targetPage }) {
  const isIncomplete = numberOfSeats > selectedSeats.length
  const remainingSeatCount = numberOfSeats - selectedSeats.length
  const unselectedSeats = new Array(remainingSeatCount).fill(null)
  const allSeats = [...selectedSeats, ...unselectedSeats]
  return (
    <div className={styles.wrapper}>
      <div className={styles.left}>
        <div className={styles.label}>
          {I18n.t('js.seatplan.seats', { count: numberOfSeats })}
        </div>
        <div className={styles.seatList}>
          {allSeats.map((seat, i) => (
            <div key={seat ? seat.id : i} className={styles.seat}>
              {seat && seat.id}
            </div>
          ))}
        </div>
      </div>
      <div className={styles.button}>
        <PrimaryButton isDisabled={isIncomplete} hasLoadingIndicator>
          {I18n.t(`js.seatplan.button.${targetPage}`)}
        </PrimaryButton>
      </div>
    </div>
  )
}

BottomSheet.propTypes = {
  numberOfSeats: PropTypes.number.isRequired,
  targetPage: PropTypes.string.isRequired,
  selectedSeats: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string.isRequired })
  ).isRequired
}

export default BottomSheet
