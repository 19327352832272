import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './__styles__/BusRow.module.sass'

function BusRow({ items, renderItem, rowClassName = '' }) {
  return (
    <tr className={classnames(styles.row, rowClassName)}>
      {items.map(item => (
        <td
          key={item.id || item.toString()}
          className={classnames(
            styles.item,
            styles[`item-cols-${item.colspan || 1}`],
            styles[`item-rows-${item.rowspan || 1}`]
          )}
          colSpan={item.colspan}
          rowSpan={item.rowspan}
        >
          {renderItem(item)}
        </td>
      ))}
    </tr>
  )
}

BusRow.propTypes = {
  items: PropTypes.array.isRequired,
  renderItem: PropTypes.func.isRequired,
  rowClassName: PropTypes.string
}

export default BusRow
