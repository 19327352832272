import { Controller } from 'stimulus'
import I18n from 'i18n-js'

export default class extends Controller {
  static targets = ['menu']

  connect() {
    // Initially hide language menu
    // Stimulus does not accept the target if it is hidden via CSS
    if (this.hasMenuTarget) {
      this.menuTarget.style.display = 'none'
    }
  }

  toggle() {
    if (this.hasMenuTarget) {
      const displayValue = this.menuTarget.style.display
      const updatedDisplayValue = displayValue === 'none' ? 'block' : 'none'
      this.menuTarget.style.display = updatedDisplayValue
    }
  }

  updateLanguage(event) {
    const newLang = event.target.dataset.lang
    if (newLang) {
      I18n.locale = newLang
    }
  }
}
