import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['button', 'form']

  submitForm() {
    this.buttonTarget.setAttribute('disabled', 'disabled')
    this.buttonTarget.classList.add('isLoading')
  }
}
