import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'

import { ViewContext } from './SeatPlan'
import Seat from './Seat'
import { isClientTouchDevice } from '../lib/client-type'
import styles from './__styles__/ColSeat.module.sass'

function ColSeat({ seat, isActive, isBonus, onClick }) {
  const viewAs = useContext(ViewContext)
  const isDriver = viewAs === 'driver'

  const isClickable = !isDriver && seat.isBookable
  const [isFocused, setFocused] = useState(false)
  const disableHover = !isClickable || isClientTouchDevice()

  function handleClick(event) {
    if (!isClickable) return
    onClick(event)
  }

  function handleMouseEnter() {
    if (disableHover) return
    setFocused(true)
  }

  function handleMouseLeave() {
    if (disableHover) return
    setFocused(false)
  }

  function handleFocus() {
    setFocused(true)
  }

  function handleBlur() {
    setFocused(false)
  }

  return (
    <button
      aria-label={seat.id}
      id={`seat_${seat.id}`}
      onClick={handleClick}
      className={styles.button}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onFocus={handleFocus}
      onBlur={handleBlur}
      disabled={!isClickable}
      type="button"
    >
      <Seat
        seat={seat}
        isActive={isActive}
        isBonus={isBonus}
        isFocused={isFocused}
      />
    </button>
  )
}

ColSeat.propTypes = {
  seat: PropTypes.shape({
    id: PropTypes.string.isRequired,
    isBookable: PropTypes.bool.isRequired
  }).isRequired,
  isActive: PropTypes.bool,
  isBonus: PropTypes.bool,
  onClick: PropTypes.func.isRequired
}

export default ColSeat
