import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['firstName', 'lastName', 'originFirstName']

  setLastName(element) {
    this.firstNameTarget.value = this.originFirstNameTarget.value
    this.lastNameTarget.value = element.target.value
  }
}
