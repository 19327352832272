/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Support component names relative to this directory:
import '../src/images'
import '../src/stylesheets/application.sass'

import 'core-js'
import 'regenerator-runtime/runtime'

if (!Element.prototype.matches) {
  Element.prototype.matches = Element.prototype.msMatchesSelector
}

if (!Element.prototype.closest) {
  Element.prototype.closest = function(s) {
    var el = this

    do {
      if (el.matches(s)) return el
      el = el.parentElement || el.parentNode
    } while (el !== null && el.nodeType === 1)
    return null
  }
}

import I18n from 'i18n-js'

import Rails from '@rails/ujs'

import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'

I18n.defaultLocale = 'de'
I18n.locale = document.documentElement.lang || 'de'
I18n.translations = {}
I18n.translations.de = require('json-loader!yaml-loader!../../../config/locales/js.de.yml').de
I18n.translations.en = require('json-loader!yaml-loader!../../../config/locales/js.en.yml').en

var componentRequireContext = require.context('components', true)
var ReactRailsUJS = require('react_ujs')

Rails.start()
ReactRailsUJS.useContext(componentRequireContext)

// eslint-disable-next-line no-undef
const application = Application.start()
const context = require.context(
  '../src/javascripts/application/controllers',
  true,
  /\.js$/
)
// eslint-disable-next-line no-undef
application.load(definitionsFromContext(context))
