import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useSwipeable } from 'react-swipeable'

import BusLevel from './BusLevel'
import styles from './__styles__/BusLevels.module.sass'

function BusLevels({
  levels,
  activeLevel,
  selectedSeats,
  bonusSeat,
  onSeatClick,
  onLevelChange,
  isMobile
}) {
  const [moveX, setMoveX] = useState(0)
  const [isSwiping, setIsSwiping] = useState(false)
  const swipeHandlers = useSwipeable({
    onSwipedRight: () => onLevelChange(levels[0].level),
    onSwipedLeft: () => onLevelChange(levels[1].level),
    onSwiping: handleSwiping,
    onSwiped: handleSwiped
  })

  function handleSwiping({ deltaX, dir }) {
    const isSwipeToUpstairs = dir === 'Left' && activeLevel === levels[0].level
    const isSwipeToBasement = dir === 'Right' && activeLevel === levels[1].level

    if (isSwipeToUpstairs || isSwipeToBasement) {
      setIsSwiping(true)
      setMoveX(deltaX)
    }
  }

  function handleSwiped() {
    setIsSwiping(false)
    setMoveX(0)
  }

  function handleBusClick(level) {
    // make whole buslevel clickable on mobile to switch between levels
    if (isMobile) return _event => onLevelChange(level)
  }

  function handleSeatClick(seat) {
    // prevents selecting a seat in the opaque non-active level
    if (seat.level !== activeLevel && isMobile) return

    onSeatClick(seat)
  }

  const wrapperClasses = classnames(styles.wrapper, {
    [styles.wrapperSwipeable]: isMobile,
    [styles.wrapperSwiping]: isSwiping
  })
  const shiftInViewport = activeLevel === levels[0].level ? '25%' : '-25%'
  const wrapperStyle = isMobile
    ? { transform: `translateX(calc(${shiftInViewport} - ${moveX}px))` }
    : undefined

  return (
    <div className={wrapperClasses} {...swipeHandlers} style={wrapperStyle}>
      {levels.map(level => {
        const isActive = level.level === activeLevel
        const busLevelClasses = classnames(styles.busLevel, {
          [styles.busLevelMobile]: isMobile,
          [styles.busLevelInactive]: isMobile && !isActive
        })

        return (
          <div key={level.level} className={busLevelClasses}>
            <BusLevel
              onBusClick={handleBusClick(level.level)}
              level={level.level}
              rows={level.rows}
              onSeatClick={handleSeatClick}
              selectedSeats={selectedSeats}
              bonusSeat={bonusSeat}
            />
          </div>
        )
      })}
    </div>
  )
}

BusLevels.propTypes = {
  levels: PropTypes.arrayOf(
    PropTypes.shape({
      level: PropTypes.string.isRequired,
      rows: PropTypes.array.isRequired
    })
  ).isRequired,
  activeLevel: PropTypes.string.isRequired,
  selectedSeats: PropTypes.array.isRequired,
  bonusSeat: PropTypes.object,
  onSeatClick: PropTypes.func.isRequired,
  onLevelChange: PropTypes.func.isRequired,
  isMobile: PropTypes.bool
}

export default BusLevels
