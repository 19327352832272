import React from 'react'

function StairsSvg(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 92 56.7" {...props}>
      <path d="M45 45L0 13.7v15.7zM31.6 0L45 45V0zM10.9 0H5.5C2.4 0 0 2.5 0 5.6V10.8L45 45 10.9 0zM29.5 0H13.8L45 45zM0 31.5V45h45zM58.8 0h9.8v45h-9.8zM86.5 0h-4.3v45h4.3c3 0 5.5-2.4 5.5-5.5v-34C92 2.4 89.6 0 86.5 0zM70.5 0h9.8v45h-9.8zM47 0h9.8v45H47zM45 51.3V47H0v4.3c0 3 2.4 5.5 5.5 5.5h34.1c3-.1 5.4-2.5 5.4-5.5z" />
    </svg>
  )
}

export default StairsSvg
