import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['form']

  submitBooking(e) {
    e.preventDefault()
    var start = document.getElementsByName('start_destination')[0].value
    var finish = document.getElementsByName('end_destination')[0].value
    var depart_date = document.getElementsByName('depart_date')[0].value
    var return_date = document.getElementsByName('return_date')[0].value
    var passengers = document.getElementsByName('passengers')[0].value
    var url = `https://booking.pinkbus.de/from/${start}/to/${finish}/${depart_date}/depart?passengers=${passengers}&return_date=${return_date}`
    window.parent.postMessage(url, '*')
    return false
  }

  submitSearch(e) {
    e.preventDefault()
    var code = document.getElementsByName('code')[0].value
    var email = document.getElementsByName('user_email')[0].value
    var url = `https://booking.pinkbus.de/bookings/${code}?email=${email}`
    window.parent.postMessage(url, '*')
    return false
  }
}
